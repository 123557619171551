import { load, save } from "../utils/storage";
import { post } from '../utils/common';

const video_time_key = 'videotimes';

let cache = load(video_time_key) || {};

export function logWatch(video_id, zone_id, times) {
    console.log('logwatch', video_id, zone_id, times);
    if (cache[video_id]) {
        cache[video_id].times += times;
    } else {
        cache[video_id] = {
            video_id,
            zone_id,
            times
        }
    }
    video_id = '' + video_id;
    const keys = Object.keys(cache);
    keys.forEach(key => {
        if (key !== video_id) {
            if (cache[key] && cache[key].times > 1000) {
                const data = cache[key];
                post(`course/watch`, { ...data, times: data.times / 1000 }, () => { });
            }
            delete cache[key];
        }
    })
    save(video_time_key, cache);
}

export function updateWatch() {
    console.log('updateWatch',);
    const keys = Object.keys(cache);
    keys.forEach(key => {
        if (cache[key] && cache[key].times > 1000) {
            const data = cache[key];
            post(`course/watch`, { ...data, times: data.times / 1000 }, () => { });
        }
        delete cache[key];
    })
    save(video_time_key, cache);
}