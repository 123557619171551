import moment from "moment";


export const formateSeconds = (time = 0) => {
    if (!time) return '0分钟'
    let secondTime = parseInt(time);
    let min = 0;
    let h = 0;
    let result = '';
    if (secondTime > 60) {
        min = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (min > 60) {//如果分钟大于60，将分钟转换成小时
            h = parseInt(min / 60)//获取小时，获取分钟除以60，得到整数小时
            min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
        }
    }
    if (h) {
        result += `${h}小时`
    }
    if (min) {
        result += `${min}分钟`
    }
    if (secondTime) {
        result += `${secondTime}秒`
    }
    return result
}

export const moment_time = (time) => { // 时间格式过滤
    if (!time) return;
    moment.locale("zh", {
        calendar: {
            lastDay: "[昨天] HH:mm",
            lastWeek: "MM-DD HH:mm",
            nextDay: "[明天] HH:mm",
            nextWeek: "MM-DD HH:mm",
            sameDay: "[今天] HH:mm",
            sameElse: function (now) {
                if (moment().year() === new Date(time).getFullYear()) {
                    return "MM-DD HH:mm";
                } else {
                    return "YYYY-MM-DD HH:mm";
                }
            }
        }
    });
    return moment(time).calendar();
}

export const NumToChinese = (num) => {  // 阿拉伯数字转汉语
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
    }
    var AA = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    var BB = ["", "十", "百", "千", "万", "亿", "点", ""];
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    if (a.length > 1) //加上小数部分(如果有小数部分) 
    {
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    }
    return re;
};