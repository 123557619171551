<template>
  <div :id="id" style="background-color: white">
    <div class="header">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="logo" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/coursedetail' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>

          <Avatar :ref="(el) => (avatarRef = el)" style="margin-right: 37px" />
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="media-wrap container_player">
          <div
            style="padding: 2vh 0; overflow: hidden"
            class="wrap flex-column"
          >
            <!-- 视频海报 -->
            <!-- <img class="cover" :src="data.cover" /> -->
            <!-- 视频区域 -->
            <div class="video_content d-flex justify-center align-center">
              <!-- <div
                class="empty_img flex-column align-center"
                v-if="!data.cover"
              >
                <span>暂无图片信息</span>
              </div>
              <img
                v-else
                v-show="!firstVideo.imgUrl"
                :src="fixurl(data.cover)"
                alt="课程封面"
              /> -->
              <div v-show="isPlayer" @click="pause" id="aliyun_video"></div>
              <div class="play" v-show="!isPlayer">
                <img
                  v-if="firstVideo.imgUrl"
                  :src="fixurl(firstVideo.imgUrl)"
                  alt="视频封面"
                />
                <div class="play_mask" @click="toPlayVideo">
                  <div class="play_btn">
                    <img style="width: 32px; height: 32px" :src="midPlay" />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex1 flex-column align-start"
              style="
                padding-left: 2rem;
                line-height: 2.4;
                background: #666;
                height: 100%;
                position: relative;
              "
            >
              <h2 style="color: white" v-text="data.courseName"></h2>
              <div
                style="
                  display: flex;
                  color: #bdbdbd;
                  font-size: 0.8rem;
                  align-items: center;
                "
              >
                <div>
                  {{ teacherName }}
                </div>
                <div style="margin-left: 4em">
                  观看次数：{{ data.numberOfView }}次
                </div>
              </div>
              <div style="position: absolute; bottom: 5px; right: 20px">
                <!-- <img :src="share" alt="" /> -->
                <img
                  @click="beCollected(data.id)"
                  style="margin-left: 50px; cursor: pointer"
                  :src="`${data.collectionState === 1 ? collect : noCollect}`"
                  alt=""
                />
              </div>
              <!-- <div
                class="flex1 flex-column justify-end"
                style="margin-bottom: 1em"
              >
                <div>
                  <div class="button-primary button-join">
                    加入学习
                    <div class="panel-download">
                      <div class="download-wrap">
                        <div class="top-arrow"></div>
                        <div class="qrcode" :qrcode="url_download"></div>
                        <div>扫码下载APP</div>
                        <div>开始药事服务在线学习</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div
          style="background: #f5f5f5; box-sizing: border-box; padding-top: 18px"
        >
          <div class="wrap intro flex-row">
            <div
              style="
                flex: 3;
                width: 10px;
                background: #fff;
                padding: 1.5rem 2rem;
              "
            >
              <div style="display: flex">
                <div
                  @click="changeTab('1')"
                  class="tabClass"
                  :style="`border-bottom:${
                    activeKey == '1' ? '3px solid #FF6300' : '1px solid #dfdcdc'
                  }`"
                >
                  <p
                    :style="`color:${activeKey == '1' ? '#FF6300' : '#000'}`"
                    class="intro-title"
                  >
                    课程简介
                  </p>
                </div>
                <div
                  @click="changeTab('2')"
                  class="tabClass"
                  :style="`border-bottom:${
                    activeKey == '2' ? '3px solid #FF6300' : '1px solid #dfdcdc'
                  }`"
                >
                  <p :style="`color:${activeKey == '2' ? '#FF6300' : '#000'}`">
                    课程目录
                  </p>
                </div>
                <div
                  style="
                    border-bottom: 1px solid #dfdcdc;
                    flex: 1;
                    height: 3rem;
                  "
                ></div>
              </div>
              <!-- 课程简介tab -->
              <div v-if="activeKey == '1'">
                <div
                  v-for="(line, index) in data.briefContent
                    ? data.briefContent.split(/[\r\n]/)
                    : []"
                  :key="index"
                  class="intro-html"
                  v-text="line"
                ></div>
              </div>
              <!-- 课程目录tab -->
              <div v-if="activeKey == '2'">
                <div style="padding: 1.5rem 0">
                  <!-- <div v-for="(item,index) in catalogList" style="height:5rem;width:100%;" :key="index">
                        <div v-for="(itemChild,index1) in item.videoList" :key="index1">

                        </div>
                    </div> -->
                  <div
                    class="chapter"
                    v-for="(i, index) in catalogList"
                    :key="index"
                  >
                    <div class="chapter_title" @click="clickChapter(i, index)">
                      {{ `第${NumToChinese(index + 1)}章：${i.catalogName}` }}
                      <span
                        :class="`t-left`"
                        :style="`transform: ${
                          !i._expand ? 'rotate(90deg)' : 'rotate(-90deg)'
                        }`"
                      ></span>
                    </div>
                    <div
                      class="chapter_content"
                      :style="`transition: all .3s; overflow: hidden; height:${
                        i._expand ? 0 : i._expand_height + 'px'
                      };`"
                    >
                      <div :id="`collace${index}`">
                        <div
                          class="chapter_item click_active"
                          @mouseenter="chapterOver(v)"
                          @mouseleave="chapterLeave"
                          @click="chapterClick(v)"
                          :style="`color:${
                            chapterActive === v.id ? '#FF6C06' : '#999'
                          }`"
                          v-for="(v, x) in i.videoList"
                          :key="v.id"
                        >
                          <div
                            class="d-flex"
                            :style="`border-bottom: ${
                              x === i.videoList.length
                                ? 'none'
                                : '1px dashed #ECECEC'
                            };cursor:pointer;align-items: center;`"
                          >
                            <div
                              style="
                                padding: 1rem;
                                display: flex;
                                align-items: center;
                              "
                              class="flex1 ellipsis"
                            >
                              <img :src="play" alt="" />
                              <span
                                :style="`color:${
                                  chapterActive === v.id && playing
                                    ? '#FF6C06'
                                    : ''
                                };margin-left:0.5rem`"
                                >{{ `${v.chapterName}` }}</span
                              >
                            </div>
                            <div>
                              <div style="position: relative">
                                <div
                                  v-if="playing && chapterActive === v.id"
                                  class="startBtn"
                                  :style="`background:${
                                    startBtnStatus && chapterOverActive === v.id
                                      ? '#F5F5F5'
                                      : '#fff'
                                  }`"
                                >
                                  <img
                                    :src="playIcon"
                                    style="margin-right: 3px"
                                    alt=""
                                  />
                                  正在播放
                                </div>
                                <div
                                  class="startStudyBtn"
                                  style="background: #ff6c06"
                                  v-if="
                                    startBtnStatus && chapterOverActive === v.id
                                  "
                                >
                                  开始学习
                                </div>
                                <img
                                  :src="time"
                                  style="
                                    width: 16px;
                                    margin-right: 3px;
                                    padding-bottom: 4px;
                                  "
                                  alt=""
                                />
                                {{ formateTime(v.videoDuration || 0, false) }}
                              </div>
                              <span></span>
                            </div>
                          </div>
                          <div class="left_line" v-show="v.isLearn"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-line"></div>
            <div style="flex: 1; width: 10px; background: #fff; padding: 15px">
              <div style="height: 50px; border-bottom: 1px solid #eeeeee">
                <h2>精选视频</h2>
              </div>
              <div
                v-for="(item, index) in courses"
                :key="index"
                class="video-item"
                @click="toCourse(item)"
              >
                <img :src="item.cover" />
                <div class="info ellipsis" v-text="item.courseName"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import "swiper/css/swiper.min.css";
import { post, getLogindata, fixurl } from "@/utils/common";
import { login } from "@/components/Login";
import router from "@/router";
import { save, load } from "@/utils/storage";
import { NumToChinese, formateSeconds } from "@/utils/helper";
import newid from "@/utils/key";
import { logWatch, updateWatch } from "../service/courseservice";

const logo = require("../assets/logo.jpg");

console.log('course inited ');

@Options({
  components: {},
})
export default class Home extends Vue {
  avatarRef = "";
  logo = logo;
  fixurl = fixurl;
  avatarModalStatus = false;
  data = {};
  // videoState = false;
  courses = [];
  //视频相关定义
  isPlayer = true;
  isCollected = false;
  player = null;
  time = require("../assets/time.png");
  play = require("../assets/play.png");
  playIcon = require("../assets/playing.png");
  share = require("../../src/assets/share.png");
  collect = require("../../src/assets/collect_play.png");
  noCollect = require("../assets/noCollect.png");
  midPlay = require("../assets/midPlay.png");
  firstVideo = {};
  timer = null;
  activeKey = "1";
  teacherName = "";
  NumToChinese = NumToChinese;
  catalogList = [];
  activeIndex = [];
  clinheight = 0;
  chapterActive = 0;
  chapterOverActive = null;
  playing = false;
  isfullscreen = false;
  isshowbar = false;
  startBtnStatus = false;
  id = newid();
  url_download = encodeURIComponent(location.origin + "/phs/app.html");

  unmounted() {
    this.clearPlayer();
  }
  mounted() {
    //是否登录
    console.log('course mounted');
    let _this = this;
    _this.load();
    setTimeout(function () {
      let isLogin = getLogindata() || {};
      if (!isLogin.headImg) {
        console.log("aaaaaaa");
        login((logon) => {
          if (logon) {
            _this.avatarRef.LoginStatus(false);

            _this.load();
          }
        });
        return;
      }
    }, 1000);
    // make qrcode
    var qr_items = document.querySelectorAll(`#${this.id} .qrcode`);

    var len = qr_items.length,
      i,
      el,
      code;
    for (i = 0; i < len; i += 1) {
      el = qr_items[i];
      if (el && (code = el.getAttribute("qrcode"))) {
        code = decodeURIComponent(code);
        if (code) {
          var w = el.clientWidth || 256;
          try {
            new QRCode(el, {
              text: code,
              width: w,
              height: w,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
    this.load();
    this.timer = setInterval(() => {
      this.saveTime();
    }, 1000);

    document.addEventListener("keydown", (event) => {
      let keycodes = event.keyCode;
      if (keycodes == 32) {
        event.preventDefault();
      }
    });
  }
  avatarModal() {
    this.avatarModalStatus = true;
  }
  avatarModalLeave() {
    this.avatarModalStatus = false;
  }
  toCourse(item) {
    document.documentElement.scrollTop = 0;
    const zone = this.$route.params.zone || 1;
    if (zone === 1) {
      router.push("/course/" + item.id);
    } else {
      router.push("/zcourse/" + zone + "/" + item.id);
    }
  }

  //收藏或取消收藏
  beCollected(id) {
    let _this = this;
    let isLogin = getLogindata() || {};
    if (!isLogin.headImg) {
      login((logon) => {
        if (logon) {
          _this.avatarRef.LoginStatus(false);

          post(
            "collection/addRemoveCollection",
            { collectionType: 2, sourceId: id },
            (res) => {
              this.data.collectionState = res.data;
            }
          );
        }
      });
      return;
    }
    post(
      "collection/addRemoveCollection",
      { collectionType: 2, sourceId: id },
      (res) => {
        this.data.collectionState = res.data;
      }
    );
  }
  //时间格式化
  formateTime(time = 0) {
    if (!time) return "0分钟";
    let secondTime = parseInt(time);
    let min = 0;
    let h = 0;
    let result = "";
    if (secondTime > 60) {
      min = parseInt(secondTime / 60);
      secondTime = parseInt(secondTime % 60);
      if (min > 60) {
        //如果分钟大于60，将分钟转换成小时
        h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
        min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
      }
    }
    if (h) {
      result += `${h}:`;
    }
    if (min) {
      result += `${min < 10 ? "0" + min : min}:`;
    }
    if (secondTime) {
      result += `${secondTime < 10 ? "0" + secondTime : secondTime}`;
    }
    return result;
  }

  changeTab(num) {
    switch (num) {
      case "1":
        this.activeKey = "1";
        break;
      case "2":
        this.activeKey = "2";
        break;
    }
  }

  saveTime() {
    if (
      this.firstVideo &&
      this.isPlayer &&
      this.player &&
      this.player.getStatus() == "playing"
    ) {
      if (this.time_prev_update) {
        const times = Date.now() - this.time_prev_update;
        logWatch(this.video_id, this.zone_id, times);
      }
      this.time_prev_update = Date.now();
      const schedule = load("schedule") || {};
      this.firstVideo.videoId &&
        (schedule[this.firstVideo.videoId + ""] = parseInt(
          this.player.getCurrentTime()
        ));
      save("schedule", schedule);
    }
  }

  getTime(memoryVideo) {
    //return返回的是自定义起播时间

    return this.firstVideo ? load("schedule")[this.firstVideo.videoId] || 0 : 0;
  }

  toPlayVideo() {
    let isLogin = getLogindata() || {};
    if (!isLogin.headImg) {
      console.log("aaaaaaa");
      login((logon) => {
        if (logon) {
          if (!this.firstVideo.id) return;
          // initPlayer("https://tb-video.bdstatic.com/tieba-smallvideo-transcode-cae/6_1a994a38b715f2547a525a23cd8eda30_cae.mp4?vt=1&pt=3&ver=&cr=3&cd=3&sid=&ft=1&tbau=2021-09-18_222f5331c91339b274e13a44b6b9600c98204b176f48733997e2356356613b63")
          this.avatarRef.LoginStatus(false);

          post(
            "course/selectCourseVideoById",
            this.firstVideo,
            ({ data = {} }, res) => {
              // console.log("播放视频 ====>", res);
              this.firstVideo = data || {};
              this.isPlayer = true;
              this.initPlayer();
            }
          );
        }
      });
      return;
    }
    if (!this.firstVideo.id) return;
    // initPlayer("https://tb-video.bdstatic.com/tieba-smallvideo-transcode-cae/6_1a994a38b715f2547a525a23cd8eda30_cae.mp4?vt=1&pt=3&ver=&cr=3&cd=3&sid=&ft=1&tbau=2021-09-18_222f5331c91339b274e13a44b6b9600c98204b176f48733997e2356356613b63")
    post(
      "course/selectCourseVideoById",
      this.firstVideo,
      ({ data = {} }, res) => {
        console.log("播放视频 ====>", res);
        this.firstVideo = data || {};

        this.isPlayer = true;
        this.initPlayer();
      }
    );
  }

  clearPlayer() {
    if (this.player) {
      this.player && this.player.dispose();
      this.player = null;
      this.isfullscreen = false;
      // if (this.fullscreen_back) {
      //   this.fullscreen_back.removeEventListener("click", this.exitfullscree);
      //   this.fullscreen_back.remove();
      //   this.fullscreen_back = null;
      // }
    }

    updateWatch();
  }

  // exitfullscree(e) {
  //   console.log('打印的e的值',e);
  //       if (e.target.matches(".icon-zuoyoujiantou2")) {
  //         this.player && this.player.fullscreenService.cancelFullScreen();
  //         this.isfullscreen = false;
  //         if (this.fullscreen_back) {
  //           this.fullscreen_back.className = `fullscreen-back ${
  //             this.isfullscreen && this.isshowbar
  //               ? ""
  //               : "fullscreen-back-hidden"
  //           }`;
  //         }
  //       }
  //     }

  initPlayer() {
    const url = this.firstVideo.videoUrl;
    this.clearPlayer();
    this.video_id = this.firstVideo.id;
    this.zone_id = this.data.hospitalZoneId;

    this.player = new Aliplayer(
      {
        id: "aliyun_video",
        source: url,
        width: "100%",
        height: "99%",
        autoplay: true,
        isLive: false,
        keyShortCuts: true,
        showBarTime: 2000,
        skinLayout: [
          { name: "bigPlayButton", align: "cc" },
          {
            name: "H5Loading",
            align: "cc",
          },
          { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
          { name: "infoDisplay" },
          { name: "tooltip", align: "blabs", x: 0, y: 56 },
          { name: "thumbnail" },
          {
            name: "prism-fullscreen-btn.fullscreen",
            align: "tl",
            x: 0,
            y: 0,
          },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              { name: "progress", align: "blabs", x: 0, y: 44 },
              { name: "playButton", align: "tl", x: 15, y: 12 },
              { name: "timeDisplay", align: "tl", x: 10, y: 7 },
              { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
              // { name: "subtitle", align: "tr", x: 15, y: 12 },
              // { name: "setting", align: "tr", x: 15, y: 12 },
              { name: "volume", align: "tr", x: 5, y: 10 },
            ],
          },
        ],

        components: [
          {
            name: "MemoryPlayComponent",
            type: AliPlayerComponent.MemoryPlayComponent,
            args: [false, this.getTime, this.saveTime],
          },
        ],
      },
      function (player) {
        console.log("player ===>", player);
      }
    );

    this.player.on("pause", () => {
      this.time_prev_update = 0;
    });

    this.player.on("play", () => {
      this.time_prev_update = Date.now();
    });
  }

  pause(e) {
    let playStatus = this.player.getStatus();
    console.log("都是是", playStatus);
    if (["playing", "pause"].includes(playStatus)) {
      if (playStatus === "pause") {
        this.player.play();
      } else {
        // this.videoState = true;
        document.getElementsByClassName("prism-big-play-btn")[0].style.display =
          "block";
        this.player.pause();
      }
    }
  }

  clickChapter(item, index) {
    if (item._expand) {
      item._expand = false;
    } else {
      item._expand = true;
      item._expand_height = document.getElementById(
        `collace${index}`
      ).clientHeight;
    }
  }
  // 鼠标移入
  chapterOver(v) {
    this.chapterOverActive = v.id;
    this.startBtnStatus = true;
  }
  chapterLeave() {
    this.startBtnStatus = false;
  }
  chapterClick = (v) => {
    this.playing = true;
    this.firstVideo = v || {};
    this.chapterActive = v.id;
    // this.data = v;
    this.isPlayer = false;
    this.clearPlayer();
    // post("web_platform/courseAddViews", { id: v.courseId }, (res) => {
    //   console.log(res);

    //   this.data.numberOfView = res;
    // });
    let isLogin = getLogindata() || {};
    if (!isLogin.headImg) {
      console.log("aaaaaaa");
      login((isLogin) => {
        if (isLogin) {
          this.avatarRef.LoginStatus(false);
          // this.load();
        }
      });
      return;
    }
  };
  selectcategory(e) {
    this.category_index = Number(e.currentTarget.dataset.index);
  }
  selectorder(e) {
    this.order_index = Number(e.currentTarget.dataset.index);
  }

  size = 3;

  load() {
    // web_platform/getCourseDetail  课程详情
    console.log("路由", this.$route);
    post(
      `web_platform/getCourseDetail?zone=${this.$route.params.zone || 1}`,
      { id: this.$route.params.id },
      (data, res) => {
        const detail = (data && data.data) || {};
        detail.cover = detail.imgUrl ? JSON.parse(detail.imgUrl)[0] : "";
        this.data = detail;
        this.teacherName = detail.teacherName;
        this.catalogList = detail.catalogList;

        this.firstVideo =
          detail.catalogList && detail.catalogList.length
            ? detail.catalogList[0].videoList[0] || {}
            : {};
        this.chapterActive = this.firstVideo.id;
        this.chapterClick(this.firstVideo);
        if (detail.id) {
          post(
            `web_platform/listPlatformCourse?zone=${
              this.$route.params.zone || 1
            }`,
            {
              page: {
                from: 0,
                size: this.size + 1,
              },
              params: {
                partId: detail.partId,
              },
            },
            (data, res) => {
              const list = (data.data && data.data.list) || [];
              this.courses = list
                .filter((item) => item.id !== detail.id)
                .slice(0, this.size)
                .map((item) => {
                  item.cover = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
                  return item;
                });
            }
          );
        }
      }
    );
  }
}
</script>

<style scoped lang="less">
.container_player {
  padding: 0;
  background: #0f0f1e;
}

.chapter_item :hover {
  background: #f5f5f5;
}
.chapter_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  color: black;
  font-weight: bold;

  span {
    transition: transform 0.24s;
    transform: rotate(0deg);
    font-size: 1.1em;
    font-weight: 100;
    color: #868686;
  }
}
// .aliyun_video {
//   .prism-player {
//     .prism-big-play-btn {

//     }
//   }
// }

// .aliyun_video .prism-player {
//   &  > div:first-child(1) {
//      display: block !important;
//   }
// }

.startBtn {
  color: rgb(255, 108, 6);
  position: absolute;
  z-index: 99;
  width: 10rem;
  height: 3rem;
  background: #fff;

  right: 0;
  text-align: right;
  bottom: -13px;
  line-height: 3rem;
}
// .startBtn :hover{
//   background:#F5F5F5;
// }
.startStudyBtn {
  width: 6rem;
  text-align: center;
  line-height: 2rem;
  font-size: 14px;
  border-radius: 5px;
  height: 2rem;
  color: #fff;
  position: absolute;
  right: 0;
  top: -3px;
}
.media-wrap {
  height: 71vh;
  background: #333;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.play {
  width: 100%;

  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play_mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);

    .play_btn {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-left: 0.4em;
      }
    }
  }
}
.video_content {
  width: 100%;
  height: 58vh;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tabClass {
  width: 7rem;
  height: 3rem;
  text-align: center;
  font-size: 19px;
  line-height: 3rem;
  box-sizing: border-box;
  cursor: pointer;
}
.cover {
  width: 1200px;
  // height: 300px;
  position: absolute;
  object-fit: cover;
}
.intro {
  .v-line {
    width: 10px;
    background-color: #f5f5f5;
    // margin: 0 0.5rem;
    align-self: stretch;
  }
  .intro-title {
    color: #f39801;
    // margin: 2.5rem 0;
  }
  .intro-html {
    text-indent: 2em;
    line-height: 2;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
.group div:hover {
  color: orange;
  cursor: pointer;
}
.button-join {
  position: relative;
  line-height: 3;
  height: 3em;
  border-radius: 1.5em;
  padding: 0 3em;
  .panel-download {
    position: absolute;
    left: 50%;
    margin-left: -9rem;
    top: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    color: black;
    line-height: 1.4;
    text-align: center;
    .download-wrap {
      background-color: white;
      border: 1px solid #cccccc;
      margin-top: 1rem;
      box-sizing: border-box;
      padding-bottom: 1rem;
      border-radius: 0.4rem;
      position: relative;
      .qrcode {
        width: 16rem;
        height: 16rem;
        margin: 1rem;
        box-sizing: border-box;
        box-shadow: 0 0 4px #aaa;
      }
    }
    .top-arrow {
      position: absolute;
      border-left: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
      width: 1rem;
      height: 1rem;
      margin-top: -0.63rem;
      transform: rotate(45deg);
      left: 7.5rem;
      background-color: white;
    }
  }
}
.button-join:hover {
  .panel-download {
    display: block;
  }
}

.video-item {
  margin: 10px 0;
  // width: 300px;
  cursor: pointer;
  height: 190px;
  position: relative;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    line-height: 2;
    padding: 0 0.5em;
    text-align: center;
  }
}
</style>

<style lang="less">
/deep/ .aliyun_video .prism-player .prism-big-play-btn {
  display: block !important;
}
</style>